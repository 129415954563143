import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { graphql } from "gatsby";

import { HeroHeader, Page, Section, usePageStyles } from "components";
import { FormContact } from "containers";

const PageContact = ({
  data: {
    config: { header, seo },
    site: {
      siteMetadata: { contact },
    },
  },
}) => {
  const styles = usePageStyles();

  const pageOptions = {
    footer: {
      isHidden: true,
    },
  };

  const headerOptions = {
    headingProps: {
      color: "primary",
      variant: "h2",
    },
    ...header,
  };

  return (
    <Page options={pageOptions} seo={seo}>
      <HeroHeader {...header} {...headerOptions}>
        {contact && (
          <Typography
            className={styles.subtitle}
            component="h3"
            variant="subtitle1"
          >
            You can also email us at
            <OutboundLink
              className={styles.link}
              href={`mailto:${contact.email}`}
            >
              {contact.email}
            </OutboundLink>
            or call us at
            <OutboundLink className={styles.link} href={`tel:${contact.phone}`}>
              {contact.phone}
            </OutboundLink>
          </Typography>
        )}
      </HeroHeader>
      <Section className="fluid">
        <FormContact className="feature" />
      </Section>
    </Page>
  );
};

PageContact.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        context: PropTypes.string,
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
      }),
    }),
  }),
};

export const contactPageQuery = graphql`
  query ContactPageQuery {
    config: pagesYaml(id: { eq: "PageContact" }) {
      header {
        context
        heading
      }
      id
      seo {
        title
      }
    }
    site {
      siteMetadata {
        contact {
          email
          phone
        }
      }
    }
  }
`;

export default PageContact;
